import React from "react";
import { 
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  //Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Paper,
  Grid,
  Tooltip,
} from "@material-ui/core";
import "./styles.scss";
import autoBind from "react-autobind";
//import PropTypes from "prop-types";
import QueueIcon from "@material-ui/icons/Queue";
import UploadIcon from "@material-ui/icons/Upload";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingButton from "@material-ui/lab/LoadingButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { handleInputChange } from "../../../utils/inputHandlers";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";

const cloneLimit = 50;
const defaultState = {
  batchArray: [],
  numClones: null,
  error: null,
  members: [],
  memberOptions: []
};

export class BatchModal extends React.Component {
  constructor() {
    super();
    this.state = { ...defaultState };
    
    autoBind(this);
    this.handleInputChange = handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...defaultState });
    }

    if (prevProps.open && !this.props.open) {
      window.onbeforeunload = null;
    }
  }

  generateUID() {
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }

  genArray(e, val) {
    e.preventDefault();

    if (this.state.batchArray.length + parseInt(val) > cloneLimit) {
      this.setState({
        error: `You can not create more than ${cloneLimit} clones in one batch!`,
      });
      return false;
    }

    let clones = [...this.state.batchArray];
    _.times(val, () =>
      clones.push({
        id: this.generateUID(),
        salesPerson: [],
        companyName: undefined,
        companyLogo: null,
        contactHTML:
          '<p><span classname="small"><strong>To:<br /></strong></span>Contact Name<br />Contact Title<br />contact@email.com</p><p><span classname="small"><strong>Main Contact:<br /></strong></span>Contact Name<br />Contact Title<br />contact@email.com</p><p>Address</p><p>Tel: 123-456-7890</p><p>www.website.com</p>',
      })
    );

    this.setState({ batchArray: clones, error: null });

    window.onbeforeunload = function (e) {
      return "Unsaved Changes";
    };
  }

  populateArray(value, index, name, element) {
    let clones = [...this.state.batchArray];

    if (element) {
      this.handleImage(element, index, clones);
      return;
    }
    else {
      clones[index][name] = value;
    }

    this.setState({ batchArray: clones });
  }

  deleteArrayItem(index) {
    let clones = [...this.state.batchArray];
    clones.splice(index, 1);

    this.setState({ batchArray: clones });
  }

  handleEditorChange(editorHTML, index) {
    this.populateArray(editorHTML, index);
  }

  handleImage(e, i, c) {
    e.preventDefault();

    let name = e.target.name;
    let file = e.target.files[0];
    let reader = new FileReader();

    if (e.target.files.length === 0) {
      return;
    }

    reader.onloadend = (el) => {
      c[i][name] = reader.result;

      this.setState({ batchArray: c });
    };

    reader.readAsDataURL(file);
  }

  handleMulti(e,val){
    console.log(e);
    console.log(val);
  }

  createMemberArr() {
    if (this.state.memberOptions.length) { return false; }

    let memberOptions = this.props.members
      ?.sort( ((a, b) => (a.Name > b.Name) ? 1 : -1) )
      .map((member) => ({
        label: member.Name,
        value: member._id.toString(),
      }));
    if (memberOptions?.length) {
      this.setState({
        memberOptions,
      });
    }
  }

  render() {
    let fullScreen = this.state.batchArray.length > 2 ? true : false;
    let canSubmit =
      this.state.batchArray.length &&
      !this.state.batchArray.filter((o) => !o.companyName).length ? true: false &&
      !this.state.batchArray.filter((o) => !o.salesPerson).length ? true: false
    return (
      <Dialog
        id="batch-modal"
        open={this.props.open}
        onClose={this.props.closeBatchDialog}
        disableEscapeKeyDown={true}
        fullWidth
        // fullScreen={fullScreen}
        maxWidth={fullScreen ? "xl" : "sm"}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <QueueIcon></QueueIcon>
            &ensp;
            <Typography variant="h6">
              Batch Clone
              {!!this.state.batchArray.length &&
                ` (${this.state.batchArray.length} Clone${
                  this.state.batchArray.length > 1 ? "s" : ""
                })`}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent
          className={this.props.batchCloning ? "disabled-modal-content" : ""}
          style={{
            background: this.state.batchArray.length
              ? "#f8f9fa"
              : "transparent",
          }}
        >
          <DialogContentText>
            {this.state.batchArray.length ? (
              <>
                Cloning{" "}
                <Typography variant="p" color="primary">
                  {this.props.title}
                </Typography>
                . Please fill out the following fields:
              </>
            ) : (
              <>
                <Box>
                  How many clones of{" "}
                  <Typography variant="p" color="primary">
                    {this.props.title}
                  </Typography>{" "}
                  would you like?
                </Box>
                <Box mt={1} mb={2} display="flex">
                  <form
                    onSubmit={(e) => this.genArray(e, this.state.numClones)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      size="small"
                      type="number"
                      name="numClones"
                      onChange={this.handleInputChange}
                      required
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: cloneLimit,
                          required: false,
                        },
                      }}
                    />
                    <Button type="submit">Add</Button>
                  </form>
                  {this.state.error && (
                    <Alert severity="error">{this.state.error}</Alert>
                  )}
                </Box>
              </>
            )}
          </DialogContentText>

          {this.state.batchArray.length ? (
            <Grid container id="clone-list" spacing={2}>
              {this.state.batchArray.map((doc, index) => {
                return (
                  <Grid
                    item
                    xs={fullScreen ? 3 : 12}
                    key={`clone-${doc.id}`}
                    style={{ minWidth: 380 }}
                  >
                    <Paper
                      elevation={1}
                      sx={{
                        "&": {
                          p: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{ "&": { mr: 2 } }}
                      >
                        {index + 1}
                      </Typography>

                      <Box style={{ flex: 1 }}>
                        <div
                          className={`company-logo-container ${
                            doc.companyLogo ? "" : "no-logo"
                          }`}
                        >
                          <label htmlFor={"companyLogo-" + index}>
                            <UploadIcon />
                            <Typography variant="overline">
                              Upload Logo
                            </Typography>
                          </label>
                          <input
                            className="company-logo"
                            name="companyLogo"
                            type="file"
                            accept="image/*"
                            id={"companyLogo-" + index}
                            onChange={(e,value) =>
                              this.populateArray(null, index, 'companyLogo', e)
                            }
                          />
                          {!!doc.companyLogo && (
                            <img src={doc.companyLogo} alt={doc.companyName} />
                          )}
                        </div>
                        <Box mt={1.5} mb={1}>
                          <TextField
                            fullWidth
                            label="Company Name"
                            variant="outlined"
                            name="companyName"
                            autoComplete="new-password" // disables autofill
                            value={this.state.batchArray[index]?.companyName}
                            onChange={(e) => this.populateArray(e.target.value, index,'companyName')}
                            error={
                              this.state.batchArray[index]?.companyName?.length
                                ? false
                                : true
                            }
                          />
                        </Box>
                        <Box mt={1.5} mb={1}>
                          <Autocomplete
                            multiple
                            autoHighlight
                            name="salesPerson"
                            className="public-btn"
                            value={this.state.batchArray[index]?.salesPerson}
                            options={this.state.memberOptions}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sales Person"
                                variant="outlined"
                                margin="none"
                                error={
                                  this.state.batchArray[index]?.salesPerson?.length
                                    ? false
                                    : true
                                }
                              />
                            )}
                            onChange={(e, value) =>
                              this.populateArray(value,index,'salesPerson')
                            }
                            onOpen={this.createMemberArr}
                          />
                        </Box>
                        <div id={`clone-toolbar-${doc.id}`} />
                        <Editor
                          value={this.state.batchArray[index]?.contactHTML}
                          apiKey="v7vnfqlxemlfsxh0aj8cys67imrf5aerbygpgavf3z0f2alc"
                          init={{
                            menubar: false,
                            plugins: "autoresize paste",
                            toolbar: `undo redo | bold underline italic | removeformat`,
                            inline: true,
                            fixed_toolbar_container: `#clone-toolbar-${doc.id}`,
                          }}
                          onEditorChange={(e) =>
                            this.handleEditorChange(e, index)
                          }
                        />
                      </Box>

                      <IconButton
                        onClick={() => this.deleteArrayItem(index)}
                        className="delete"
                        sx={{
                          "&": {
                            mr: -2,
                          },
                        }}
                      >
                        <DeleteIcon color="error"></DeleteIcon>
                      </IconButton>
                    </Paper>
                  </Grid>
                );
              })}
              {this.state.batchArray.length < cloneLimit ? (
                <Grid
                  id="quick-add-clone"
                  item
                  xs={fullScreen ? 3 : 12}
                  style={{
                    display: "flex",
                    minWidth: 380,
                    minHeight: fullScreen ? 514 : "auto",
                  }}
                >
                  <Tooltip
                    title="Click to add another clone"
                    followCursor
                    enterDelay={1000}
                    placement="left"
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        "&": {
                          p: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                      onClick={(e) => this.genArray(e, 1)}
                    >
                      <AddCircleOutlineIcon />
                    </Paper>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
          ) : null}
        </DialogContent>

        {this.props.batchCloning && (
          <LinearProgress
            className="batch-progress-bar"
            variant="determinate"
            value={this.props.batchProgress}
          />
        )}

        <DialogActions id="modal-confirm">
          <Button
            onClick={() => this.props.closeDialog()}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() =>
              this.props.confirmAction(this.state.batchArray, this.props.docId)
            }
            disabled={!canSubmit}
            pending={this.props.batchCloning}
          >
            Clone
            {!!this.state.batchArray.length && (
              <span>&ensp;[{this.state.batchArray.length}]</span>
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}
export default BatchModal;
